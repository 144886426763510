<template>
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      href="/dashboard"
      style="background-color: white"
    >
      <!-- <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
      </div> -->
      <img
        src="../../assets/favicon.png"
        style="height: 35px; width: 160px"
        alt="LOGO"
      />
      <!-- <span class="ms-1 font-weight-bold">Innovative Toll</span> -->
      <!-- <div class="sidebar-brand-text mx-3">Innovative Toll</div> -->
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item pl-1">
      <!-- <a class="nav-link" href="/dashboard"> -->
      <router-link class="nav-link" to="/dashboard"
        ><i class="fas fa-fw fa-tachometer-alt"></i>
        <span>DASHBOARD</span></router-link
      >
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider" />
    <!-- Heading -->
    <!-- Nav Item - Pages Collapse Menu -->

    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-ticket-alt"></i>
        <span>TICKETS</span>
      </a>
      <div
        id="collapse"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Tickets Overview</h6>
          <router-link class="collapse-item" to="/my-ticket"
            >My Tickets</router-link
          >
          <router-link class="collapse-item" to="/assigned-ticket"
            >Assigned Tickets</router-link
          >
          <router-link class="collapse-item" to="/all-tickets"
            >All Tickets</router-link
          >

          <h6 class="collapse-header">Admin:</h6>
          <!-- <router-link
            v-if="this.isAdmin"
            class="collapse-item"
            to="/past-turnover"
            >Past Turnover</router-link
          > -->
          <router-link class="collapse-item" to="/change-request"
            >Change Requests</router-link
          >
        </div>
      </div>
    </li>
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse1"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-robot"></i>
        <span>PROJECTS</span>
      </a>
      <div
        id="collapse1"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Project Overview</h6>
          <router-link class="collapse-item" to="/my-project"
            >My Projects</router-link
          >
          <router-link class="collapse-item" to="/project"
            >Projects</router-link
          >
          <router-link class="collapse-item" to="/daily-task"
            >Daily Tasks</router-link
          >
        </div>
      </div>
    </li>
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse2"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-plus"></i>
        <span>ADDONS</span>
      </a>
      <div
        id="collapse2"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Ticket Addons</h6>
          <router-link class="collapse-item" to="/category"
            >Ticket Category</router-link
          >
          <router-link class="collapse-item" to="/department">
            Departments</router-link
          >
          <router-link class="collapse-item" to="/status"
            >Ticket Status</router-link
          >
          <router-link class="collapse-item" to="/priority"
            >Priority</router-link
          >
        </div>
      </div>
    </li>

    <!-- <li class="nav-item pl-1">
      <router-link class="nav-link" to="/daily-report"><i class="fas fa-file-alt"></i>
        <span>DAILY REPORT</span></router-link>
    </li> -->
    <hr class="sidebar-divider d-none d-md-block" />
  </ul>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      role: "",
      // username: "",
    };
  },
  methods: {
    goBack() {
      // Navigate back in the browser history
      window.history.back();
      window.location.reload();
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.username = this.currentUser.username;
    this.role = localStorage.getItem("role");
    this.isAdmin = this.role === "Supervisor";
  },
};
</script>
