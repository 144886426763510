<template>
  <footer class="sticky-footer bg-white">
    <div class="container my-auto">
      <div class="copyright text-center my-auto">
        <span> &copy;{{ currentYear }} Innovative Toll Solution </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    const currentYear = new Date().getFullYear();
    return { currentYear };
  },
};
</script>
